body {
  margin: 0;
  font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'PingFang SC', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
@media (max-width: 1230px) {
  body {
    overflow: hidden;
  }

  .forLanding {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120vw;
    -ms-zoom: 0.8;
    -o-transform-origin: 0 0;
    -o-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -moz-transform-origin: 0px 0;
    -webkit-transform-origin: 0 0;
    -webkit-transform: scale(0.8);
  }
}

@media (max-width: 1199px) {
  body {
    overflow: hidden;
  }

  .forLanding {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160vw;
    -ms-zoom: 0.6;
    -o-transform-origin: 0 0;
    -o-transform: scale(0.6);
    -moz-transform: scale(0.6);
    -moz-transform-origin: 0px 0;
    -webkit-transform-origin: 0 0;
    -webkit-transform: scale(0.6);
  }
}
